import { Box, Typography } from "@mui/material";
import React from "react";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import PropTypes from "prop-types";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import { orderBox } from "../../../../lib/styleConstants";
// import Dropzone from "react-dropzone";
// import AppButton from "../../../global/elements/AppButton";
import ChecklistAndGuidlines from "../../missions/shared/ChecklistAndGuidelinesUpload";

const ObjAndDesc = ({ values, setFieldValue, serialNo }) => {
  return (
    <Box sx={orderBox}>
      <TextStructures
        text="Objectives and description of the order"
        number={serialNo}
      />
      <Typography variant="body1" mt={3}>
        Please provide the details of what you hope to accomplish in the
        following order. This information will assist us in instructing the
        drone operator effectively. If you have any additional comments or
        unaddressed requirements, please include them here.
      </Typography>

      <Box mt={2}>
        <TextFieldInput
          setFieldValue={setFieldValue}
          values={values}
          label={"Detailed description"}
          name={"description"}
          type="text"
          rows={3}
          multiline={true}
          // required
        />
      </Box>
      <Typography variant="body1" mt={2}>
        Please provide a pdf of any guidelines you would like the Operators to
        follow.
      </Typography>
      <Box mt={1} mb={2}>
        <ChecklistAndGuidlines values={values} setFieldValue={setFieldValue} />
      </Box>
      <br />
    </Box>
  );
};

ObjAndDesc.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  serialNo: PropTypes.string,
};

ObjAndDesc.defaultProps = {
  values: {},
  serialNo: "6",
};

export default ObjAndDesc;
